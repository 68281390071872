<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t("add") }}

                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template> -->
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-toolbar color="primary darken-1" dark>
              <v-card-title>
                <span class="">{{
                  (editedIndex === -1 ? $t("add") : $t("edit")) +
                    " " +
                    $t("users.user")
                }}</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      persistent-hint
                      v-model="editedItem.userName"
                      :rules="[rules.required]"
                      :label="$t('users.userName')"
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="editedIndex == -1" cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.password"
                      :label="$t('users.password')"
                      :rules="[rules.required]"
                      type="password"
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="editedIndex == -1" cols="12" md="6">
                    <v-text-field
                      type="password"
                      v-model="editedItem.confirmPassword"
                      :rules="[rules.required, rules.confirmPassword]"
                      :label="$t('users.confirmPassword')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.email"
                      :rules="[rules.required, rules.email]"
                      :label="$t('email')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.phoneNumber"
                      :label="$t('phone')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      :items="roles"
                      item-text="roleCaption"
                      item-value="roleName"
                      v-model="editedItem.roleNames"
                      :label="$t('groups.roles')"
                      multiple
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select
                      :items="nodes"
                      item-text="caption"
                      item-value="nodeGuid"
                      v-model="editedItem.nodesGuid"
                      :label="$t('nodes.nodes')"
                      multiple
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="green darken-1"
                class="white--text"
                @click="save"
                :disabled="!valid"
                :min-width="100"
              >
                <v-icon>mdi-content-save-outline</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <confirm-dialog
        :openDialog="dialogDelete"
        :onClicked="deleteItemConfirm"
        :onClose="closeDelete"
      ></confirm-dialog>
      <v-dialog v-model="dialogChangePassword" persistent max-width="400px">
        <v-form ref="form1" v-model="valid1" lazy-validation>
          <v-card>
            <v-toolbar color="primary darken-1" dark>
              <v-card-title>
                <span class=""> {{ $t("users.changePassword") }}</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.password"
                      :label="$t('users.password')"
                      :rules="[rules.required]"
                      type="password"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      type="password"
                      v-model="editedItem.confirmPassword"
                      :rules="[rules.required, rules.confirmPassword]"
                      :label="$t('users.confirmPassword')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="green darken-1"
                class="white--text"
                @click="saveChangePassword"
                :disabled="!valid1"
                :min-width="100"
              >
                <v-icon>mdi-content-save-outline</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="closeChangePassword">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        :items-per-page="5"
        :loading="loading"
        :loading-text="$t('loading')"
        class=""
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="dialog = !dialog">
              {{ $t("add") }}

              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="changePassword(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-key </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("users.changePassword") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mx-1"
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red darken-2"
                  icon
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      loading: true,
      valid: true,
      valid1: true,
      dialog: false,
      dialogDelete: false,
      dialogChangePassword: false,
      editedIndex: -1,
      search: "",
      headers: [
        { text: this.$t("users.userName"), value: "userName" },
        { text: this.$t("email"), value: "email" },
        { text: this.$t("phone"), value: "phoneNumber" },
        //{ text: this.$t("branches.branchName"), value: "branchName" },
        //{ text: this.$t("doctors.doctorName"), value: "doctorName" },
        { text: this.$t("nodes.nodes"), value: "nodes.caption" },
        { text: "", value: "actions" },
      ],
      nodes: [],
      users: [],
      roles: [],
      editedItem: {},
      defaultItem: {
        userName: "",
        email: null,
        password: "",
        confirmPassword: "",
        phoneNumber: null,
      },
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        confirmPassword: () =>
          this.editedItem.confirmPassword === this.editedItem.password ||
          this.$t("users.notMatchPassword"),
        email: (v) => /.+@.+\..+/.test(v) || this.$t("users.validEmail"),
      },
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
    this.getRoles();
    this.getNodes();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },

    changePassword(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogChangePassword = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.users[this.editedIndex];
      axios
        .delete("Accounts/DeleteUser?id=" + deleteItem.id)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeChangePassword() {
      this.dialogChangePassword = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      axios
        .get("Accounts/GetUsers")
        .then((response) => {
          this.users = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getRoles() {
      axios
        .get("Accounts/GetRoles")
        .then((response) => {
          this.roles = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getNodes() {
      axios
        .get("Nodes/Get")
        .then((response) => {
          this.nodes = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.users[this.editedIndex];
          updatedItem.userName = this.editedItem.userName;
          updatedItem.email = this.editedItem.email;
          updatedItem.phoneNumber = this.editedItem.phoneNumber;
          updatedItem.branchGuid = this.editedItem.branchGuid;
          updatedItem.doctorGuid = this.editedItem.doctorGuid;
          updatedItem.groupGuid = this.editedItem.groupGuid;

          axios
            .post("Accounts/UpdateUser", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
          Object.assign(this.users[this.editedIndex], this.editedItem);
        } else {
          axios
            .post("Accounts/AddUser", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },

    saveChangePassword() {
      var val = this.$refs.form1.validate();
      if (val) {
        var updatedItem = this.users[this.editedIndex];
        var changePasswordModel = {
          id: updatedItem.id,
          password: this.editedItem.password,
          confirmPassword: this.editedItem.confirmPassword,
        };
        axios
          .post("Accounts/ChangeUserPasswordByAdmin", changePasswordModel)
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
              this.closeChangePassword();
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }

            this.refreshTable();
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            this.refreshTable();

            console.log(e);
          });
      }
    },
  },
};
</script>

<style></style>
